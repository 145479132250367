import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const namespaced = true

export default {
  namespaced,
  state: () => ({
    validationErrors: {},
    loading: false,
    single: {},
    data: {

      marketing_spend: {
        loading: false,
        value: null,
      },

      revenue: {
        loading: false,
        value: null,
      },

      roas: {
        loading: false,
        value: null,
      },

      finance_applications: {
        loading: false,
        values: {
          total: null,
          accepted: null,
          declined: null,
        }
      },

      conversions: {
        loading: false,
        values: {
          impression_kits: null,
          aligners_after_impression_kits: null,
          scan_appointments: null,
          aligners_after_scan_appointments: null,
          aligners: null,
          aligners_after_initial_purchase: null,
        }
      },

      cac: {
        loading: false,
        value: null,
      },

    },
  }),
  mutations,
  getters,
  actions
}
