import {

  FINANCIAL_FETCH_MARKETING_SPEND_REQUEST,
  FINANCIAL_FETCH_MARKETING_SPEND_SUCCESS,
  FINANCIAL_FETCH_MARKETING_SPEND_FAILURE,

  FINANCIAL_FETCH_REVENUE_REQUEST,
  FINANCIAL_FETCH_REVENUE_SUCCESS,
  FINANCIAL_FETCH_REVENUE_FAILURE,

  FINANCIAL_FETCH_ROAS_REQUEST,
  FINANCIAL_FETCH_ROAS_SUCCESS,
  FINANCIAL_FETCH_ROAS_FAILURE,

  FINANCIAL_FETCH_FINANCE_APPLICATIONS_REQUEST,
  FINANCIAL_FETCH_FINANCE_APPLICATIONS_SUCCESS,
  FINANCIAL_FETCH_FINANCE_APPLICATIONS_FAILURE,

  FINANCIAL_FETCH_CONVERSION_STATISTICS_REQUEST,
  FINANCIAL_FETCH_CONVERSION_STATISTICS_SUCCESS,
  FINANCIAL_FETCH_CONVERSION_STATISTICS_FAILURE,

  FINANCIAL_FETCH_CAC_REQUEST,
  FINANCIAL_FETCH_CAC_SUCCESS,
  FINANCIAL_FETCH_CAC_FAILURE,

} from './types'

export default {

  fetchMarketingSpend({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_MARKETING_SPEND_REQUEST)
    return window.axios.get(`/financial/marketing-spend`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_MARKETING_SPEND_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_MARKETING_SPEND_FAILURE, error)
      throw error
    })
  },

  fetchRevenue({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_REVENUE_REQUEST)
    return window.axios.get(`/financial/revenue`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_REVENUE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_REVENUE_FAILURE, error)
      throw error
    })
  },

  fetchRoas({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_ROAS_REQUEST)
    return window.axios.get(`/financial/roas`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_ROAS_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_ROAS_FAILURE, error)
      throw error
    })
  },

  fetchFinanceApplications({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_FINANCE_APPLICATIONS_REQUEST)
    return window.axios.get(`/financial/finance-applications`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_FINANCE_APPLICATIONS_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_FINANCE_APPLICATIONS_FAILURE, error)
      throw error
    })
  },

  fetchConversionStatistics({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_CONVERSION_STATISTICS_REQUEST)
    return window.axios.get(`/financial/conversions`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_CONVERSION_STATISTICS_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_CONVERSION_STATISTICS_FAILURE, error)
      throw error
    })
  },

  fetchCac({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_CAC_REQUEST)
    return window.axios.get(`/financial/cac`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_CAC_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_CAC_FAILURE, error)
      throw error
    })
  },

  fetchStatistics ({ commit }, { from, to }) {
    commit(FINANCIAL_FETCH_REQUEST)
    return window.axios.get(`/financial/statistics`, { params: { from, to } }).then(response => {
      commit(FINANCIAL_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(FINANCIAL_FETCH_FAILURE, error)
      throw error
    })
  },
}
