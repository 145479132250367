export const FINANCIAL_FETCH_MARKETING_SPEND_REQUEST = 'FINANCIAL_FETCH_MARKETING_SPEND_REQUEST'
export const FINANCIAL_FETCH_MARKETING_SPEND_SUCCESS = 'FINANCIAL_FETCH_MARKETING_SPEND_SUCCESS'
export const FINANCIAL_FETCH_MARKETING_SPEND_FAILURE = 'FINANCIAL_FETCH_MARKETING_SPEND_FAILURE'

export const FINANCIAL_FETCH_REVENUE_REQUEST = 'FINANCIAL_FETCH_REVENUE_REQUEST'
export const FINANCIAL_FETCH_REVENUE_SUCCESS = 'FINANCIAL_FETCH_REVENUE_SUCCESS'
export const FINANCIAL_FETCH_REVENUE_FAILURE = 'FINANCIAL_FETCH_REVENUE_FAILURE'

export const FINANCIAL_FETCH_ROAS_REQUEST = 'FINANCIAL_FETCH_ROAS_REQUEST'
export const FINANCIAL_FETCH_ROAS_SUCCESS = 'FINANCIAL_FETCH_ROAS_SUCCESS'
export const FINANCIAL_FETCH_ROAS_FAILURE = 'FINANCIAL_FETCH_ROAS_FAILURE'

export const FINANCIAL_FETCH_FINANCE_APPLICATIONS_REQUEST = 'FINANCIAL_FETCH_FINANCE_APPLICATIONS_REQUEST'
export const FINANCIAL_FETCH_FINANCE_APPLICATIONS_SUCCESS = 'FINANCIAL_FETCH_FINANCE_APPLICATIONS_SUCCESS'
export const FINANCIAL_FETCH_FINANCE_APPLICATIONS_FAILURE = 'FINANCIAL_FETCH_FINANCE_APPLICATIONS_FAILURE'

export const FINANCIAL_FETCH_CONVERSION_STATISTICS_REQUEST = 'FINANCIAL_FETCH_CONVERSION_STATISTICS_REQUEST'
export const FINANCIAL_FETCH_CONVERSION_STATISTICS_SUCCESS = 'FINANCIAL_FETCH_CONVERSION_STATISTICS_SUCCESS'
export const FINANCIAL_FETCH_CONVERSION_STATISTICS_FAILURE = 'FINANCIAL_FETCH_CONVERSION_STATISTICS_FAILURE'

export const FINANCIAL_FETCH_CAC_REQUEST = 'FINANCIAL_FETCH_CAC_REQUEST'
export const FINANCIAL_FETCH_CAC_SUCCESS = 'FINANCIAL_FETCH_CAC_SUCCESS'
export const FINANCIAL_FETCH_CAC_FAILURE = 'FINANCIAL_FETCH_CAC_FAILURE'