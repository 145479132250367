<template>
  <router-view />
</template>

<script>
import financialModule from '../../store/financial'

export default {
  beforeCreate () {
    this.$store.registerModule('financial', financialModule)
  },
  destroyed () {
    this.$store.unregisterModule('financial')
  }
}
</script>
