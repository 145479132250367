import {
  FINANCIAL_FETCH_MARKETING_SPEND_REQUEST,
  FINANCIAL_FETCH_MARKETING_SPEND_SUCCESS,
  FINANCIAL_FETCH_MARKETING_SPEND_FAILURE,

  FINANCIAL_FETCH_REVENUE_REQUEST,
  FINANCIAL_FETCH_REVENUE_SUCCESS,
  FINANCIAL_FETCH_REVENUE_FAILURE,

  FINANCIAL_FETCH_ROAS_REQUEST,
  FINANCIAL_FETCH_ROAS_SUCCESS,
  FINANCIAL_FETCH_ROAS_FAILURE,

  FINANCIAL_FETCH_FINANCE_APPLICATIONS_REQUEST,
  FINANCIAL_FETCH_FINANCE_APPLICATIONS_SUCCESS,
  FINANCIAL_FETCH_FINANCE_APPLICATIONS_FAILURE,

  FINANCIAL_FETCH_CONVERSION_STATISTICS_REQUEST,
  FINANCIAL_FETCH_CONVERSION_STATISTICS_SUCCESS,
  FINANCIAL_FETCH_CONVERSION_STATISTICS_FAILURE,

  FINANCIAL_FETCH_CAC_REQUEST,
  FINANCIAL_FETCH_CAC_SUCCESS,
  FINANCIAL_FETCH_CAC_FAILURE,
} from './types'

export default {

  /**
   * Marketing Spend
   */
  [FINANCIAL_FETCH_MARKETING_SPEND_REQUEST] (state) {
    state.data.marketing_spend.loading = true
    state.data.marketing_spend.value = null
  },
  [FINANCIAL_FETCH_MARKETING_SPEND_SUCCESS] (state, { data }) {
    state.data.marketing_spend.loading = false
    state.data.marketing_spend.value = data
  },
  [FINANCIAL_FETCH_MARKETING_SPEND_FAILURE] (state) {
    state.data.marketing_spend.loading = false
    state.data.marketing_spend.value = null
  },

  /**
   * Revenue
   */
  [FINANCIAL_FETCH_REVENUE_REQUEST] (state) {
    state.data.revenue.loading = true
    state.data.revenue.value = null
  },
  [FINANCIAL_FETCH_REVENUE_SUCCESS] (state, { data }) {
    state.data.revenue.loading = false
    state.data.revenue.value = data
  },
  [FINANCIAL_FETCH_REVENUE_FAILURE] (state) {
    state.data.revenue.loading = false
    state.data.revenue.value = null
  },

  /**
   * Roas
   */
  [FINANCIAL_FETCH_ROAS_REQUEST] (state) {
    state.data.roas.loading = true
    state.data.roas.value = null
  },
  [FINANCIAL_FETCH_ROAS_SUCCESS] (state, { data }) {
    state.data.roas.loading = false
    state.data.roas.value = data
  },
  [FINANCIAL_FETCH_ROAS_FAILURE] (state) {
    state.data.roas.loading = false
    state.data.roas.value = null
  },

  /**
   * Finance Applications
   */
  [FINANCIAL_FETCH_FINANCE_APPLICATIONS_REQUEST] (state) {
    state.data.finance_applications.loading = true
    state.data.finance_applications.values.total = null
    state.data.finance_applications.values.accepted = null
    state.data.finance_applications.values.declined = null
  },
  [FINANCIAL_FETCH_FINANCE_APPLICATIONS_SUCCESS] (state, { data }) {
    state.data.finance_applications.loading = false
    state.data.finance_applications.values.total = data.finance_applications
    state.data.finance_applications.values.accepted = data.finance_accepted
    state.data.finance_applications.values.declined = data.finance_declined
  },
  [FINANCIAL_FETCH_FINANCE_APPLICATIONS_FAILURE] (state) {
    state.data.finance_applications.loading = false
    state.data.finance_applications.values.total = null
    state.data.finance_applications.values.accepted = null
    state.data.finance_applications.values.declined = null
  },

  /**
   * Conversion Statistics
   */
  [FINANCIAL_FETCH_CONVERSION_STATISTICS_REQUEST] (state) {
    state.data.conversions.loading = true
    state.data.conversions.values.impression_kits = null
    state.data.conversions.values.aligners_after_impression_kits = null
    state.data.conversions.values.scan_appointments = null
    state.data.conversions.values.aligners_after_scan_appointments = null
    state.data.conversions.values.aligners = null
    state.data.conversions.values.aligners_after_initial_purchase = null
  },
  [FINANCIAL_FETCH_CONVERSION_STATISTICS_SUCCESS] (state, { data }) {
    state.data.conversions.loading = false
    state.data.conversions.values.impression_kits = data.impression_kits_converted
    state.data.conversions.values.aligners_after_impression_kits = data.aligners_after_impression_kits
    state.data.conversions.values.scan_appointments = data.scan_appointments_converted
    state.data.conversions.values.aligners_after_scan_appointments = data.aligners_after_scan_appointments
    state.data.conversions.values.aligners = data.aligners_converted
    state.data.conversions.values.aligners_after_initial_purchase = data.aligners_after_initial_purchase
  },
  [FINANCIAL_FETCH_CONVERSION_STATISTICS_FAILURE] (state) {
    state.data.conversions.loading = false
    state.data.conversions.values.impression_kits = null
    state.data.conversions.values.aligners_after_impression_kits = null
    state.data.conversions.values.impression_kits = null
    state.data.conversions.values.aligners_after_scan_appointments = null
    state.data.conversions.values.aligners = null
    state.data.conversions.values.aligners_after_initial_purchase = null
  },

  /**
   * Conversion Statistics
   */
  [FINANCIAL_FETCH_CAC_REQUEST] (state) {
    state.data.cac.loading = true
    state.data.cac.value = null
  },
  [FINANCIAL_FETCH_CAC_SUCCESS] (state, { data }) {
    state.data.cac.loading = false
    state.data.cac.value = data
  },
  [FINANCIAL_FETCH_CAC_FAILURE] (state) {
    state.data.cac.loading = false
    state.data.cac.value = null
  },

}
